export default {
  text: {
    en: 'No events within selected filters',
    es: 'No hay eventos dentro de los filtros seleccionados',
  },
  reset: {
    en: 'Reset all',
    es: 'Restablecer todo',
  },
}
