export default {
  title: {
    en: 'No events',
    es: 'Sin eventos',
  },
  text: {
    en: 'Oops, there is no events, try again later',
    es: 'Vaya, no hay eventos, inténtalo de nuevo más tarde',
  },
}
